import React, { useEffect, useState } from 'react';
import { XMLParser } from 'fast-xml-parser';
import './ItemList.css';

interface Item {
  name: string;
  nominal: number;
  lifetime: number;
  restock: number;
  min: number;
  quantmin: number;
  quantmax: number;
  cost: number;
  category: {
    name: string;
  };
  usage: {
    names: string[];
  };
  value: {
    names: string[];
  };
}

const ItemList: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);

  useEffect(() => {
    const fetchAndParseXml = async () => {
      try {
        const response = await fetch('/types.xml');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const xmlText = await response.text();
        const parser = new XMLParser({
          ignoreAttributes: false,
          attributeNamePrefix: '@_',
        });
        const jsonResult = parser.parse(xmlText);

        if (!jsonResult?.types?.type) {
          throw new Error('Unexpected JSON structure.');
        }

        const itemsArray = jsonResult.types.type.map((item: any) => ({
          name: item['@_name'] || 'Unknown',
          nominal: parseInt(item.nominal, 10) || 0,
          lifetime: parseInt(item.lifetime, 10) || 0,
          restock: parseInt(item.restock, 10) || 0,
          min: parseInt(item.min, 10) || 0,
          quantmin: parseInt(item.quantmin, 10) || 0,
          quantmax: parseInt(item.quantmax, 10) || 0,
          cost: parseInt(item.cost, 10) || 0,
          category: item.category ? { name: item.category['@_name'] || 'Unknown' } : { name: 'Unknown' },
          usage: {
            names: Array.isArray(item.usage) ? item.usage.map((u: any) => u['@_name']) : []
          },
          value: {
            names: Array.isArray(item.value) ? item.value.map((v: any) => v['@_name']) : []
          },
        }));

        setItems(itemsArray);
        setFilteredItems(itemsArray);
      } catch (err) {
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAndParseXml();
  }, []);

  useEffect(() => {
    const filtered = items.filter(item =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchQuery, items]);

  if (loading) return <div className="loading">Loading...</div>;

  return (
    <div className="item-list">
      <h1>Item List</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by item name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      {filteredItems.length === 0 ? (
        <p>No items found</p>
      ) : (
        filteredItems.map((item, index) => (
          <div className="card" key={index}>
            <h2>{item.name}</h2>
            <div className="item-detail"><strong>Lifetime:</strong> {item.lifetime} seconds</div>
            <div className="item-detail"><strong>Restock:</strong> {item.restock} seconds</div>
            <div className="item-detail"><strong>Minimum quantity:</strong> {item.min}</div>
            <div className="item-detail"><strong>Maximum quantity:</strong> {item.quantmax}</div>
            <div className="item-detail"><strong>Category:</strong> {item.category.name}</div>
            {item.usage.names.length > 0 && (
              <div className="item-detail"><strong>Locations:</strong> {item.usage.names.join(', ')}</div>
            )}
            {item.value.names.length > 0 && (
              <div className="item-detail"><strong>Location tier:</strong> {item.value.names.join(', ')}</div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default ItemList;
