import React from 'react';
import './Rules.css';

const Info: React.FC = () => {
  return (
    <div className="rules">
      <h1>Server info</h1>

      <h2>Location</h2>
      Finland
      <h2>Server style</h2>
      Player versus Player
      <h2>Mods</h2>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2818322021" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          Hacking system
        </a>
      </p>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2879941785" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          NewKillFeed
        </a>
      </p>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=3176951485" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          Crafting Repair Tools
        </a>
      </p>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=1870524790" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          Airdrop-Upgraded
        </a>
      </p>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=3131502760" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          Unlimited Stamina
        </a>
      </p>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2106663048" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          NatureOverhaul
        </a>
      </p>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=1646187754" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          CodeLock
        </a>
      </p>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2546583347" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          BuildEverywhere
        </a>
      </p>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=1565871491" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          BuilderItems
        </a>
      </p>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>
        <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=1623711988" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
          VanillaPlusPlusMap
        </a>
      </p>
      <h2>Wildlife</h2>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>All 1.5x from vanilla</p>
      <h2>Infected</h2>
      <p style={{ marginTop: "8px", marginBottom: "0px" }}>0.8x from vanilla</p>
    </div>
  );
};

export default Info;
