import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './Home';
import Rules from './Rules';
import Info from './Info';
import Loot from './ItemList';
import TicketForm from './TicketForm';
import Donate from './Donate';
import Killfeed from './Killfeed';
import KillfeedHeatmap from './KillfeedHeatmap';
import PlayerStatsComponent from './PlayerStats';

const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentHash, setCurrentHash] = useState(window.location.hash);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentHash(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <div className="app">
      <div
        className={`hamburger ${isMenuOpen ? 'open' : ''}`}
        onClick={toggleMenu}
        aria-label="Toggle navigation menu"
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="#home" onClick={toggleMenu}>Home</a></li>
          <li><a href="#playerStats" onClick={toggleMenu}>Player statistics</a></li>
          <li><a href="#killfeed" onClick={toggleMenu}>Server killfeed</a></li>
          <li><a href="#killfeedHeatmap" onClick={toggleMenu}>Kill heatmap</a></li>
          <li><a href="#rules" onClick={toggleMenu}>Server rules</a></li>
          <li><a href="#info" onClick={toggleMenu}>Server info</a></li>
          <li><a href="#loot" onClick={toggleMenu}>Loot table</a></li>
          <li><a href="#ticket" onClick={toggleMenu}>Create ticket</a></li>
          <li><a href="#donations" onClick={toggleMenu}>Donations</a></li>
        </ul>
      </nav>

      {currentHash === '#home' && <Home />}
      {currentHash === '' && <Home />}
      {currentHash === '#playerStats' && <PlayerStatsComponent />}
      {currentHash === '#killfeed' && <Killfeed />}
      {currentHash === '#killfeedHeatmap' && <KillfeedHeatmap />}
      {currentHash === '#rules' && <Rules />}
      {currentHash === '#info' && <Info />}
      {currentHash === '#loot' && <Loot />}
      {currentHash === '#ticket' && <TicketForm />}
      {currentHash === '#donations' && <Donate />}
    </div>
  );
};

export default App;
