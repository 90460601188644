import React from 'react';
import './App.css';
import lfodLogo from './lfodlogo.png';

const Home: React.FC = () => {
  return (
    <div className="content">
      <img
        src={lfodLogo}
        alt="LFOD Logo"
        style={{
          width: "200px"
        }} />
      <h1 className="title">DayZ</h1>
      <h2 className="subtitle">Live Free Or Die</h2>
      <h3 className="tagline">Beyond Vanilla</h3>
    </div>
  );
};

export default Home;
