import React, { useState, useEffect, useRef } from 'react';
import './KillfeedHeatmap.css';
import fullMap from './fullmap.png';

interface KillFeedEvent {
  date: string;
  distance: string;
  killedId: string;
  killedName: string;
  killedPosition: string;
  killerId: string;
  killerName: string;
  killerPosition: string;
  timestamp: string;
  weapon: string;
}

interface KillFeedResponse {
  events: KillFeedEvent[];
}

const KillfeedHeatmap: React.FC = () => {
  const [killFeed, setKillFeed] = useState<KillFeedEvent[]>([]);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [canvasDimensions, setCanvasDimensions] = useState<{ width: number, height: number }>({ width: 800, height: 800 });

  const fetchKillfeed = async () => {
    try {
      const response = await fetch('https://us-central1-lfod-45ef2.cloudfunctions.net/api/getKillfeed');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: KillFeedResponse = await response.json();
      const sortedEvents = data.events.sort((a, b) => {
        const dateTimeA = new Date(`${a.date}T${a.timestamp}`);
        const dateTimeB = new Date(`${b.date}T${b.timestamp}`);
        return dateTimeB.getTime() - dateTimeA.getTime();
      });

      setKillFeed(sortedEvents);
    } catch (error) {
      console.error('Error fetching killfeed:', error);
    }
  };

  useEffect(() => {
    fetchKillfeed();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setCanvasDimensions({
        width: 2000,
        height: 2000
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const drawHeatmap = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const mapImg = new Image();
      mapImg.src = fullMap;
      mapImg.onload = () => {
        const scale = canvasDimensions.width / 15360;
        const aspectRatio = mapImg.width / mapImg.height;
        const canvasAspectRatio = canvasDimensions.width / canvasDimensions.height;

        let drawWidth, drawHeight;
        if (canvasAspectRatio > aspectRatio) {
          drawWidth = canvasDimensions.width;
          drawHeight = drawWidth / aspectRatio;
        } else {
          drawHeight = canvasDimensions.height;
          drawWidth = drawHeight * aspectRatio;
        }

        canvas.width = drawWidth;
        canvas.height = drawHeight;

        ctx.drawImage(mapImg, 0, 0, drawWidth, drawHeight);

        ctx.globalCompositeOperation = 'lighter';

        killFeed.forEach(event => {
          const [x, y] = event.killerPosition.split(',').map(coord => parseFloat(coord));
          const xPos = x * scale;
          const yPos = (15360 - y) * scale;

          ctx.beginPath();
          const intensity = 1;
          const radius = 1000 * scale;
          const color = `rgba(255, 0, 0, ${Math.min(1, intensity)})`;
          ctx.arc(xPos, yPos, radius, 0, 2 * Math.PI);
          ctx.fillStyle = color;
          ctx.fill();
        });
      };
    };

    drawHeatmap();
  }, [killFeed, canvasDimensions]);

  return (
    <div className="heatmap-container">
      <canvas
        ref={canvasRef}
        className="heatmap-canvas"
      />
    </div>
  );
};

export default KillfeedHeatmap;
