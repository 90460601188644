import React, { useState, useEffect } from 'react';
import './PlayerStats.css';

interface KillFeedEvent {
  date: string;
  distance: string;
  killedId: string;
  killedName: string;
  killedPosition: string;
  killerId: string;
  killerName: string;
  killerPosition: string;
  timestamp: string;
  weapon: string;
}

interface KillFeedResponse {
  events: KillFeedEvent[];
}

interface PlayerStats {
  id: string;
  name: string;
  kills: number;
  deaths: number;
  longestKill: number;
  kdRatio: number;
}

type SortKey = 'name' | 'kills' | 'deaths' | 'longestKill' | 'kdRatio';

const PlayerStatsComponent: React.FC = () => {
  const [playerStats, setPlayerStats] = useState<PlayerStats[]>([]);
  const [sortKey, setSortKey] = useState<SortKey>('kdRatio');
  const [sortAsc, setSortAsc] = useState<boolean>(false);

  useEffect(() => {
    const fetchKillfeed = async () => {
      try {
        const response = await fetch('https://us-central1-lfod-45ef2.cloudfunctions.net/api/getKillfeed');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: KillFeedResponse = await response.json();
        const sortedEvents = data.events.sort((a, b) => {
          const dateTimeA = new Date(`${a.date}T${a.timestamp}`);
          const dateTimeB = new Date(`${b.date}T${b.timestamp}`);
          return dateTimeB.getTime() - dateTimeA.getTime();
        });

        const stats: Record<string, PlayerStats> = {};
        sortedEvents.forEach(event => {
          const killerId = event.killerId;
          const killedId = event.killedId;
          const distance = parseFloat(event.distance);

          if (!stats[killerId]) {
            stats[killerId] = {
              id: killerId,
              name: event.killerName,
              kills: 0,
              deaths: 0,
              longestKill: 0,
              kdRatio: 0
            };
          }
          stats[killerId].kills += 1;
          if (distance > stats[killerId].longestKill) {
            stats[killerId].longestKill = distance;
          }

          if (!stats[killedId]) {
            stats[killedId] = {
              id: killedId,
              name: event.killedName,
              kills: 0,
              deaths: 0,
              longestKill: 0,
              kdRatio: 0
            };
          }
          stats[killedId].deaths += 1;
        });

        Object.values(stats).forEach(player => {
          player.kdRatio = player.deaths === 0 ? player.kills : player.kills / player.deaths;
        });

        setPlayerStats(Object.values(stats));
      } catch (error) {
        console.error('Error fetching killfeed:', error);
      }
    };

    fetchKillfeed();
    const intervalId = setInterval(fetchKillfeed, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSort = (key: SortKey) => {
    if (sortKey === key) {
      setSortAsc(prevSortAsc => !prevSortAsc);
    } else {
      setSortKey(key);

      // Set default sorting order based on the key
      if (key === 'name') {
        setSortAsc(true);  // Sort by name in ascending order by default
      } else {
        setSortAsc(false); // Sort by other numeric fields in descending order by default
      }
    }
  };

  const sortedPlayerStats = [...playerStats].sort((a, b) => {
    const aValue = a[sortKey];
    const bValue = b[sortKey];

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortAsc ? aValue - bValue : bValue - aValue;
    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    return 0;
  });

  return (
    <div className="player-stats-container">
      <h1>Player Statistics</h1>
      <div className="sort-dropdown">
        <label htmlFor="sort-select">Sort by:</label>
        <select
          id="sort-select"
          value={sortKey}
          onChange={(e) => handleSort(e.target.value as SortKey)}
        >
          <option value="name">Player Name</option>
          <option value="kills">Kills</option>
          <option value="deaths">Deaths</option>
          <option value="longestKill">Longest Kill</option>
          <option value="kdRatio">K/D Ratio</option>
        </select>
      </div>
      <div className="player-cards">
        {sortedPlayerStats.map((player, index) => (
          <div key={player.id} className="player-card">
            <div className="timestamp"># {index + 1}</div>
            <h2>{player.name}</h2>
            <p><strong>Kills:</strong> {player.kills}</p>
            <p><strong>Deaths:</strong> {player.deaths}</p>
            <p><strong>Longest Kill:</strong> {player.longestKill.toFixed(2)} meters</p>
            <p><strong>K/D Ratio:</strong> {player.kdRatio.toFixed(2)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayerStatsComponent;
