import React, { useState, useEffect } from 'react';
import './Killfeed.css';

interface KillFeedEvent {
  date: string;
  distance: string;
  killedId: string;
  killedName: string;
  killedPosition: string;
  killerId: string;
  killerName: string;
  killerPosition: string;
  timestamp: string;
  weapon: string;
}

interface KillFeedResponse {
  events: KillFeedEvent[];
}

const Killfeed: React.FC = () => {
  const [killFeed, setKillFeed] = useState<KillFeedEvent[]>([]);
  const [newItems, setNewItems] = useState<Set<string>>(new Set());

  const fetchKillfeed = async () => {
    try {
      const response = await fetch('https://us-central1-lfod-45ef2.cloudfunctions.net/api/getKillfeed');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: KillFeedResponse = await response.json();
      const sortedEvents = data.events.sort((a, b) => {
        const dateTimeA = new Date(`${a.date}T${a.timestamp}`);
        const dateTimeB = new Date(`${b.date}T${b.timestamp}`);
        return dateTimeB.getTime() - dateTimeA.getTime();
      });

      const currentIds = new Set(killFeed.map(event => event.killedId));
      const newIds = new Set(sortedEvents.filter(event => !currentIds.has(event.killedId)).map(event => event.killedId));

      if (newIds.size > 0) {
        setNewItems(newIds);
      }

      setKillFeed(sortedEvents);
    } catch (error) {
      console.error('Error fetching killfeed:', error);
    }
  };

  useEffect(() => {
    fetchKillfeed();

    const intervalId = setInterval(() => {
      fetchKillfeed();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="item-list">
      <h1>Killfeed</h1>
      {killFeed.length === 0 ? (
        <p>Loading killfeed...</p>
      ) : (
        killFeed.map((item, index) => (
          <div
            className={`card ${newItems.has(item.killedId) ? 'flash' : ''}`}
            key={index}
          >
            <div className="timestamp">
              <span>{`${item.date} ${item.timestamp}`}</span>
            </div>
            <h2>"{item.killerName}" killed "{item.killedName}" with {item.weapon}</h2>
            <p><strong>Distance:</strong> {item.distance} meters</p>
          </div>
        ))
      )}
    </div>
  );
};

export default Killfeed;
