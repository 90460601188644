import React from 'react';
import './Modal.css';

interface ModalProps {
    show: boolean;
    message: string;
    onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ show, message, onClose }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-backdrop">
            <div className="modal">
                <div className="modal-content">
                    <p>{message}</p>
                    <button onClick={onClose} className="modal-button">Close</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
