import React from 'react';
import paypalDonateButton from './paypal-donate-button.webp';
import paypalqrcode from './paypalqrcode.png';

const DonateButton: React.FC = () => {
    const handleClick = () => {
        window.open('https://www.paypal.com/donate/?hosted_button_id=VBGJDQ9N369B8', '_blank');
    };

    return (
        <div className="rules" style={{ textAlign: 'center', padding: '20px' }}>
            <h1>Donations</h1>
            <p style={{ marginTop: '8px', marginBottom: '16px' }}>
                Supporting our server helps cover costs, but donations are not required to play.
            </p>
            <p style={{ marginTop: '8px', marginBottom: '16px', fontWeight: "800", color: "#ff6666" }}>
                If you're donating to sign up for priority queue access, please remember to include your Steam64 ID in the donation notes and open a new ticket on this site.
            </p>
            <p style={{ marginTop: '8px', marginBottom: '16px' }}>
                Donations are used to cover server expenses and ensure the server runs 24/7. All donations are final and intended solely for server maintenance. No refunds are possible. By donating, you agree to our donation terms and forfeit any PayPal dispute rights.
            </p>
            <p style={{ marginTop: '16px', marginBottom: '16px', fontWeight: "800", color: "#ff6666" }}>
                All payments are final and non-refundable.
            </p>
            <p style={{ marginTop: '8px', marginBottom: '16px' }}>
                Priority queue prices are as follows:
            </p>
            <p style={{ marginTop: '8px', marginBottom: '16px' }}>
                1 month: €5
            </p>
            <p style={{ marginTop: '8px', marginBottom: '16px' }}>
                Lifetime: €100
            </p>

            <p style={{ marginTop: '16px', marginBottom: '16px' }}>
                After donating for priority queue, please submit a ticket for "Priority Queue." Your ticket will be processed as soon as possible, but no later than 24 hours. An admin will update your ticket with additional information after reviewing your donation.
            </p>
            <p style={{ marginTop: '16px', marginBottom: '16px', fontWeight: "800", color: "#ff6666" }}>
                Donations that are below the minimum amount required for priority queue access will be considered as general support for covering server costs.
            </p>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '20px'
            }}>
                <img
                    src={paypalqrcode}
                    alt="Donate with PayPal"
                    style={{ width: '200px', height: 'auto', cursor: 'pointer' }}
                />
                <img
                    src={paypalDonateButton}
                    alt="Donate with PayPal"
                    style={{ width: '200px', height: 'auto', cursor: 'pointer' }}
                    onClick={handleClick}
                />
            </div>
        </div>
    );
};

export default DonateButton;
