import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import './TicketForm.css';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import Modal from './Modal';

const firebaseConfig = {
  apiKey: "AIzaSyAflaafGMBpoCfn9kdbqZ0ObpmAr92M9LQ",
  authDomain: "lfod-45ef2.firebaseapp.com",
  projectId: "lfod-45ef2",
  storageBucket: "lfod-45ef2.appspot.com",
  messagingSenderId: "518645597966",
  appId: "1:518645597966:web:945361ee6342bc468f795f",
  measurementId: "G-BMRLXBBYJK"
};

interface Ticket {
  explanation: string;
  fileName: string;
  status: string;
  subject: string;
  ticketId: string;
  timestamp: string;
  username: string;
  adminResponse: string;
}

const TicketForm: React.FC = () => {
  const [username, setUsername] = useState('');
  const [subject, setSubject] = useState('');
  const [explanation, setExplanation] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const status = "new";
  const [fileName, setFileName] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadSpeed, setUploadSpeed] = useState<string>("");
  const [ticketId, setTicketId] = useState<string | null>(null);
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [modalMessage, setModalMessage] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [closingTicket, setClosingTicket] = useState(false);

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  useEffect(() => {
    const storedTicketId = localStorage.getItem('ticketId');
    if (storedTicketId) {
      setTicketId(storedTicketId);
      fetchTicketStatus(storedTicketId);

      const intervalId = setInterval(() => {
        if (ticketId) {
          fetchTicketStatus(storedTicketId);
        }
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, []);

  const fetchTicketStatus = async (ticketId: string) => {
    try {
      const response = await fetch('https://us-central1-lfod-45ef2.cloudfunctions.net/api/getTicketStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ticketId }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.timestamp && result.timestamp._seconds) {
        const timestamp = new Date(result.timestamp._seconds * 1000);
        result.timestamp = timestamp.toLocaleString();
      }

      setTicket(result);
    } catch (error) {
      localStorage.removeItem('ticketId');
      setTicketId(null);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFile(file);
      setFileName(file.name);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!username || !subject || !explanation) {
      setModalMessage('Please fill in all fields.');
      setShowModal(true);
      return;
    }

    // Conditionally set fileName based on subject
    let adjustedFileName = fileName;
    if (subject === "Priority queue") {
      adjustedFileName = "notSet";
    }

    const ticketData = {
      username,
      subject,
      explanation,
      fileName: adjustedFileName, // Use adjustedFileName here
      status,
    };

    try {
      const response = await fetch('https://us-central1-lfod-45ef2.cloudfunctions.net/api/submitTicket', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ticketData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const ticketId = result.ticketId;

      if (ticketId) {
        localStorage.setItem('ticketId', ticketId);
        setTicketId(ticketId);

        if (file) {
          await uploadFile(ticketId);
        }

        setModalMessage(`Ticket submitted successfully. Your ticket ID is ${ticketId}.`);
        fetchTicketStatus(ticketId);
        setUsername("");
        setSubject("");
        setExplanation("");
        setFile(null);
      } else {
        setModalMessage('Ticket submitted successfully, but no ticket ID was returned.');
      }
      setShowModal(true);
    } catch (error) {
      console.error('Error submitting ticket:', error);
      setModalMessage('Error submitting ticket.');
      setShowModal(true);
    }
  };

  const uploadFile = (ticketId: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!file) {
        resolve();
        return;
      }

      const storageRef = ref(storage, `uploads/${ticketId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      let startTime = Date.now();

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);

          const timeElapsed = (Date.now() - startTime) / 1000;
          const bytesTransferred = snapshot.bytesTransferred / 1024;
          const speed = (bytesTransferred / timeElapsed).toFixed(2);

          setUploadSpeed(
            bytesTransferred >= 1024
              ? `${(bytesTransferred / 1024).toFixed(2)} MB/s`
              : `${speed} KB/s`
          );
        },
        (error) => {
          console.error('Error uploading file:', error);
          setModalMessage('Failed to upload file.');
          setShowModal(true);
          reject(error);
        },
        () => {
          setUploadProgress(0);
          setUploadSpeed("");
          resolve();
        }
      );
    });
  };

  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileName(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleCloseTicket = async () => {
    if (!ticketId) return;
    setClosingTicket(true);
    try {
      const response = await fetch('https://us-central1-lfod-45ef2.cloudfunctions.net/api/closeTicket', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ticketId }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setModalMessage('Ticket closed successfully.');
      setShowModal(true);
      localStorage.removeItem('ticketId');
      setTicketId(null);
      setTicket(null);
      setClosingTicket(false);
    } catch (error) {
      console.error('Error closing ticket:', error);
      setModalMessage('Error closing ticket.');
      setShowModal(true);
      setClosingTicket(false);
    }
  };

  return (
    <div className="ticket-form">
      <Modal show={showModal} message={modalMessage} onClose={() => setShowModal(false)} />
      {ticketId ? (
        <div className="form-group">
          {ticket ? (
            <div>
              <h1>Your Ticket Details</h1>
              <div className="card">
                <h2>Ticket ID: {ticket.ticketId}</h2>
                <h2>Ticket Submitted: {ticket.timestamp}</h2>
                <h2>Ticket Subject: {ticket.subject}</h2>
                <h2>{ticket.subject === "Priority queue" ? "Steam64 ID:" : "Explanation:"} {ticket.explanation}</h2>
                <h2>Ticket Status: {ticket.status === "new" ? "Waiting for Admin to open the ticket" : "In progress by Admin"}</h2>
                <h2>Admin response: {ticket.adminResponse || "No response yet"}</h2>
                <button onClick={handleCloseTicket} style={{ marginTop: "16px", padding: "10px 20px", backgroundColor: "#ff6666", border: "none", color: "#fff", borderRadius: "4px", cursor: "pointer", fontSize: "1em" }}>
                  {closingTicket ? "Closing ticket..." : "Close Ticket"}
                </button>
              </div>
            </div>
          ) : (
            <p>Loading ticket status...</p>
          )}
        </div>
      ) : (
        <div>
          <h1>Submit a Ticket</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Your (latest) In-Game Username:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject:</label>
              <select
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              >
                <option value="" disabled>Select a subject</option>
                <option value="Priority queue">Priority queue</option>
                <option value="Suspected cheating">Suspected cheating</option>
                <option value="Grieving">Grieving</option>
                <option value="Illegal base raid">Illegal base raid</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="explanation">{subject === "Priority queue" ? "Your Steam64 ID:" : "Free explanation of what happened:"}</label>
              <textarea
                id="explanation"
                value={explanation}
                onChange={(e) => setExplanation(e.target.value)}
                rows={5}
                required
              />
            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              {subject !== "Priority queue" && (
                <div className="form-group file-group">
                  <label htmlFor="file">Upload evidence:</label>
                  <button
                    type="button"
                    className="file-button"
                    onClick={handleFileButtonClick}
                  >
                    {file ? file.name : 'Choose File'}
                  </button>
                  <input
                    type="file"
                    id="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  {file && (
                    <button
                      type="button"
                      style={{
                        marginTop: "16px",
                        padding: "10px 20px",
                        backgroundColor: "#ff6666",
                        border: "none",
                        color: "#fff",
                        borderRadius: "4px",
                        cursor: "pointer",
                        fontSize: "1em",
                      }}
                      onClick={handleRemoveFile}>
                      Remove File
                    </button>
                  )}
                </div>
              )}
              {uploadProgress > 0 && (
                <div className="upload-progress">
                  <p>Uploading: {uploadProgress.toFixed(2)}% ({uploadSpeed})</p>
                </div>
              )}
              <p style={{ marginTop: '16px', marginBottom: '16px', fontWeight: "800", color: "#ff6666" }}>
                READ THE SERVER RULES BEFORE SUBMITTING A TICKET!
              </p>
              <div className="form-actions">
                <button type="submit">{uploadProgress ? "Submitting Ticket..." : "Submit Ticket"}</button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default TicketForm;