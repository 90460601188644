import React from 'react';
import './Rules.css';

const Rules: React.FC = () => {
  return (
    <div className="rules">
      <h1>Community Guidelines and rules</h1>
      <p>Welcome to Live Free Or Die - Beyond Vanilla! We're thrilled to have you as part of our community. To ensure a fun and fair experience for everyone, please adhere to the following guidelines:</p>
      
      <h2>1. Respect All Players</h2>
      <ul>
        <li>Treat all players with respect, regardless of their skill level, playstyle, or background.</li>
        <li>Hate speech, harassment, or discrimination of any kind will not be tolerated.</li>
        <li>Avoid personal attacks or insults. We encourage healthy competition and friendly banter, but know where to draw the line.</li>
      </ul>

      <h2>2. No Cheating or Exploiting</h2>
      <ul>
        <li>Cheating, hacking, or using exploits to gain an unfair advantage, including duping and glitching is strictly prohibited and will result in a permanent ban.</li>
        <li>Do not use third-party programs or scripts that modify game mechanics or give you an edge over others.</li>
        <li>Metagaming (giving out other people's positions) will result in a ban.</li>
        <li>Report any bugs or exploits you find to the server admins immediately.</li>
      </ul>

      <h2>3. Player versus Player (PvP)</h2>
      <ul>
        <li>Maximum size of a group is five people.</li>
        <li>Engaging in PvP combat is a part of DayZ and is highly encouraged in Live Free Or Die server. Unnecessary griefing will not be tolerated.</li>
        <li>Killing of fresh spawns is not recommended, but is NOT prohibited.</li>
        <li>Assisting of other groups outside your own in a fight or raiding a base will result in a ban.</li>
        <li>Minimum time for rotating users in a group is 30 minutes. The 30 minute timer starts when the removed player logs off or the server restarts.</li>
        <li>There are no safe zones in the server. Anyone can take part of PvP anywhere, any time.</li>
      </ul>

      <h2>4. Base Building Rules</h2>
      <ul>
        <li>A group can have a maxmium of two bases in the server. If more bases are being found for the same players, all the extra bases and items inside will be deleted by Admins.</li>
        <li>No building on main roads.</li>
        <li>Base can have maximum of three walls in height.</li>
        <li>Breaking any of these rules will result in a base being removed by Admins. There will be no exceptions and there is no right of appeal.</li>
        <li>In case of negatively affecting server performance, an Admin has the right to remove either the whole base or parts of it.</li>
      </ul>

      <h2>5. Base Raiding Rules</h2>
      <ul>
      <li>Raiding player bases is part of the game. Anyone can raid any base at all times, but a base cannot be taken into own control if ANY of the original Code Locks is still attached. For not complying with this prohibition will result always in a ban of every player attending.</li>
      <li>Every base raid is highly recommended to being recorded. This is for your own good in case of false reports of breaking the raid rules.</li>
      <li>Raiding of incorrectly built bases is allowed, but you are not allowed to conquer it.</li>
      <li>Logging out inside of an enemy's base is not allowed.</li>
      <li>If you believe your base was raided using prohibited style, you may appeal it through the the "Create ticket" link on this page.</li>
      </ul>

      <h2>6. Communicate Effectively</h2>
      <ul>
        <li>Use the in-game chat and server Discord (or other communication platforms) respectfully. Spamming, advertising, or sharing inappropriate content is forbidden.</li>
        <li>If you encounter a problem or have a dispute with another player, try to resolve it amicably. If you can't, contact a server admin for assistance.</li>
      </ul>

      <h2>7. Follow the Server's Theme</h2>
      <ul>
        <li>Live Free Or Die is meant to be a PvP server. Make sure your actions align with the server's theme. You are free to play the game in the way you want, but keep in mind that others will most likely KoS (Kill on Sight). </li>
        <li>Always familiarize yourself with and follow the server's specific rules.</li>
      </ul>

      <h2>8. No Stream Sniping or Meta-Gaming</h2>
      <ul>
        <li>If you're watching a stream or have out-of-game information about other players, do not use this knowledge to gain an advantage in the game.</li>
        <li>Meta-gaming, or using real-life knowledge that your character wouldn't have, is not allowed.</li>
      </ul>

      <h2>9. Keep it Friendly</h2>
      <ul>
        <li>Remember, it's just a game! Keep the atmosphere positive and fun for everyone.</li>
        <li>If you're feeling frustrated, take a break rather than taking it out on others.</li>
      </ul>

      <h2>10. Reporting Issues</h2>
      <ul>
        <li>If you encounter any rule breakers or have any issues, report them to the admins through the appropriate channels. Include evidence (screenshots, video, etc.) whenever possible.</li>
        <li>Do not take matters into your own hands; let the admins handle it.</li>
        <li>Losing your gear by not logging out before a server restart/update will not be compensated. If the server crashes and you loose gear due it might be compensated through creating a ticket and providing evidence.</li>
      </ul>

      <h2>11. Admin Decisions Are Final</h2>
      <ul>
        <li>Admins are here to ensure a smooth and enjoyable experience for everyone. Please respect their decisions and understand that they are made in the best interest of the community.</li>
        <li>If you believe a decision was unfair, you may appeal it through the the "Create ticket" link on this page, but do so respectfully.</li>
      </ul>

      <p>By joining our server, you agree to follow these guidelines. Failure to adhere to these rules may result in warnings, temporary bans, or permanent removal from the server. Let's work together to build a great community and have fun surviving in the world of DayZ!</p>

      <p>Happy surviving!<br />Live Free Or Die team</p>
    </div>
  );
};

export default Rules;
